const paramsKeys = {
  PROMOCODE: 'code',
  APP_IDENTITY: 'app-identity',
  OTC_CODE: 'otc-code',
  ERROR: 'error',
  PERMANENT_TOKEN: 'permanent_access_token',
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  DIIA_CALLBACK_URL: 'diia_success_url',

  // change-email flow params
  TOKEN: 'token',
  EMAIL: 'email',
  PROFILE_NOTIFICATION: 'profile_notif',

  PLAN: 'plan',
  REGISTRATION_SOURCE: 'registration_source',
  ADD_NEW_DEVICE: 'add_new_device',

  // trackings
  FUNNEL: 'funnel',
  CLICK_ID: 'clickid',
  IR_CLICK_ID: 'irclickid',
  UTM_SOURCE: 'utm_source',
  UTM_MEDIUM: 'utm_medium',
  UTM_CAMPAIGN: 'utm_campaign',
  AD_SET: 'ad_set',
  SHORTCUT: 'shortcut',
  AD_GROUP_ID: 'adgroupid',
};

const getParsedParams = (): Record<keyof typeof paramsKeys, string | null> => {
  const params = new URLSearchParams(window.location.search);

  return Object.entries(paramsKeys).reduce((acc: Record<string, string | null>, entry) => {
    const [key, value] = entry;

    acc[key] = params.get(value);

    return acc;
  }, {});
};

export { paramsKeys, getParsedParams };
